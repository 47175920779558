import React from "react";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";

import Navbar from "./navbar";
import Footer from "./footer";
import "../styles/requestService.css";

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sendStatus: "Send",
      from_name: "",
      phone: "",
      email: "",
      company: "",
      address: "",
      message: "",
      errors: {},
    };
  }

  validateForm = () => {
    const errors = {};
    const { from_name, phone, email, company, address, message } = this.state;

    if (!from_name) errors.from_name = "Name is required";
    if (!phone) errors.phone = "Phone is required";
    if (!email) errors.email = "Email is required";
    if (!company) errors.company = "Business name is required";
    if (!address) errors.address = "Address is required";
    if (!message) errors.message = "Message is required";

    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  sendEmail = (e) => {
    e.preventDefault();
    if (!this.validateForm()) return;

    emailjs
      .sendForm(
        "service_6j67ki6",
        "template_7iak9s8",
        e.target,
        "user_WpLvYUKh59KgaFazMjV56"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
    this.showAlert();
  };

  showAlert = () => {
    Swal.fire({
      title: "Message Sent",
      text: "Your message was successfully sent to Nevada Exhaust Cleaning!",
      type: "success",
    });
  };

  render() {
    const { errors } = this.state;

    return (
      <div className="request-service-page">
        <Navbar />
        <div className="contact-box">
          <div className="service-headers">
            <header>
              <h2 data-shadow="dang!">Request a Service</h2>
            </header>
            <article>
              <h4>
                Fill out this short form and a Nevada Exhaust Cleaning
                representative will contact you within the next business day. If
                you need immediate assistance, please call:
              </h4>
            </article>
            <a href="tel:775-355-9915">(775) 355-9915</a>
          </div>
          <section className="box">
            <form onSubmit={this.sendEmail}>
              <div className="top-inputs">
                <div className="input-container">
                  <input
                    className="input"
                    placeholder="Name"
                    type="text"
                    name="from_name"
                    onChange={this.handleInputChange}
                  />
                  {errors.from_name && (
                    <span className="error">{errors.from_name}</span>
                  )}
                </div>

                <div className="input-container">
                  <input
                    className="input"
                    placeholder="Phone"
                    type="text"
                    name="phone"
                    onChange={this.handleInputChange}
                  />
                  {errors.phone && (
                    <span className="error">{errors.phone}</span>
                  )}
                </div>

                <div className="input-container">
                  <input
                    className="input"
                    placeholder="Email"
                    type="email"
                    name="email"
                    onChange={this.handleInputChange}
                  />
                  {errors.email && (
                    <span className="error">{errors.email}</span>
                  )}
                </div>

                <div className="input-container">
                  <select name="service_choice" id="service_choice">
                    <option value="'no specific service chosen'">
                      Select Service...
                    </option>
                    <option value="Restaurant Hood Cleaning">
                      Restaurant Hood Cleaning
                    </option>
                    <option value="Commercial Laundry Duct">
                      Commercial Laundry Duct
                    </option>
                    <option value="Fire Cleanup">Fire Cleanup</option>
                    <option value="'no specific service chosen'">Other</option>
                  </select>
                </div>
              </div>

                        <div className="bottom-inputs">
                <div className="input-container">

                <input
                  className="business-input"
                  placeholder="Business Name"
                  type="text"
                  name="company"
                  onChange={this.handleInputChange}
                />
                {errors.company && (
                  <span className="error">{errors.company}</span>
                )}
                            </div>
                <div className="input-container">

                <input
                  className="business-input"
                  placeholder="Address needing service"
                  type="text"
                  name="address"
                  onChange={this.handleInputChange}
                />
                {errors.address && (
                  <span className="error">{errors.address}</span>
                                )}
                                </div>

                <textarea
                  placeholder="Message"
                  name="message"
                  onChange={this.handleInputChange}
                />
                {errors.message && (
                  <span className="error">{errors.message}</span>
                )}

                <button className="button" type="submit">
                  {this.state.sendStatus}
                </button>
              </div>
            </form>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}
